<template>
  <b-container class="new-details-container">
    <div class="news-container-caption">
      <h5>
        <a href="javascript:void(0)" @click="back">
          新闻中心
        </a>
        >{{newsList[index].title}}</h5>
    </div>
    <article v-if="index === 0" class="article">
      <div class="text-center"><img src="https://imgcdn.hemiao100.com/weihaojiao/upload/rechargeAdvertise/image/42010016042552/2018-11/1edeaf9b-fb8c-4ded-bf8a-2b03be4e949a.jpg" alt="" class="img-fluid mb40"></div>
      <p>
        2018年9月，拥有丰富经验的互联网母婴服务商——禾苗，面向零售店发布了“HONG云”系列会员精准营销系统，针对性地解决中小型零售店客户开卡耗费时间长、开卡场景局限、会员维护不到位、导购管理混乱等问题。
      </p>
      <p>
        “HONG云”作为一款专为中小型零售店打造的“智慧零售”会员管理系统，它的发布，对中小型零售店有着重要的意义，为中小型零售店提供了一个发展机会。同时意味着再小的门店，也可以拥有强大的精准营销工具和管理平台。
      </p>
      <p class="no-margin">CRM之于企业的意义，国际权威研究机构曾得出这样的结论：</p>
      <p class="no-margin">“把客户的满意度提高5个百分比，其结果是企业的利润增加一倍。”</p>
      <p class="no-margin">“一个非常满意的客户其购买意愿比一个满意客户高出6倍。”</p>
      <p class="no-margin">“2/3的客户离开供应商是因为供应商对他们的关怀不够。”</p>
      <p>“93%的企业CEO认为，客户关系管理是使企业成功和更有竟争能力的最重要的因素。”</p>

      <p class="no-margin">“HONG云”更是清晰地认识到了导入CRM会员管理系统对于中小型零售店的重要性：</p>
      <p class="no-margin">它能帮助零售店快速拓展市场，制造更多的销售机会；</p>
      <p class="no-margin">可以提高业务处理流程的自动化程度，提高导购员的工作能力和积极性，并有效地减少人力成本负担；</p>
      <p>它能提高客户满意度，帮助零售店保留更多的老客户，并更好的吸引新客户。</p>

      <p>
        “HONG云”依托着禾苗诞生，禾苗团队有百度、腾讯、阿里巴巴、搜狗、优酷等优势资源支持，拥有百度联盟服务网络，是国内领先的母婴行业互联网+服务商，禾苗服务现已覆盖大半个中国。
      </p>
      <p>
        “HONG云”，为中小型零售店提供了新出路，风驰的智慧零售专列，不知道哪些中小零售店能赶得上。
      </p>
    </article>
    <article v-if="index === 1" class="article">
      <p>2018年9月，禾苗（武汉）信息技术有限公司打造了国内母婴行业首家AI人脸识别技术，利用AI人脸识别+大数据，助力母婴新零售的高效增长。</p>

      <p>禾苗AI人脸识别，是一款基于线下母婴店销售、服务等多场景量身打造的AI技术。此项技术有效地融合四大核心：电子名片、精准维客、高效营销、轻松社交。</p>

      <p>禾苗AI人脸识别技术，通过母婴店的摄像头/摄像机对人脸信息进行采集，快速提取人脸特征，识别并匹配客户信息，获取客户最近消费记录和新增营销机会，反馈至导购员，促进导购员精准维客、快速营销，有效避免销售交流过程中，信息混乱、销售失败的尴尬。</p>

      <p>禾苗人脸识别系统主要包括四个组成部分，分别是人脸图像采集及检测、人脸图像预处理、人脸图像特征提取以及匹配与识别。从摄像机/摄像头采集到的人脸信息，会反馈至禾苗数据库，并与数据库中存储的特征模板进行搜索匹配，通过设定一个阈值，当相似度超过这一阈值，则把匹配得到的结果输出发送至导购端。</p>

      <p>禾苗AI人脸识别技术有效地提高了母婴店营销机会，实现母婴导购5秒内详细了解客户姓名、阶段购物推荐、购物偏好品牌、历史购买记录等操作。</p>

      <p>在应用案例上，禾苗曾将AI人脸识别技术应用到多家线下母婴店，识别成功率达到了98%以上。</p>

      <p>禾苗人工智能以大数据技术为基础，通过人工智能算法为用户提供全新的购物体验。禾苗“大数据+人工智能”的结合，两者相辅相成，共同助力母婴行业智慧零售的发展！</p>
    </article>
    <article v-if="index === 2" class="article">
      <div class="text-center"><img src="https://imgcdn.hemiao100.com/weihaojiao/upload/rechargeAdvertise/image/42010016042552/2018-11/3d454e8f-b8b9-4d7d-b9df-7ed74d36ae8c.png" alt="" class="img-fluid mb40"></div>

      <p class="no-margin"> 2018年5月，京东PLUS与爱奇艺VIP打通一周，新增超百万会员，累计plus会员数同比2016年增速高达700% </p>
      <p class="no-margin"> 2018年6月，爱奇艺会员收入25亿元人民币，同比增长66%，会员规模达6710万人，同比增长75% </p>
      <p class="no-margin"> 阿里在超级会员上再推出88VIP，权益含优酷、饿了么、淘票票、虾米等会员服务 </p>
      <p> 星巴克不是会员体系的鼻祖，但成为了会员体系教科书，指导门店如何一分钟打造“忠实用户” </p>

      <p>“超级会员”时代已经来了！母婴零售店自然不能错过这个机会。</p>

      <p>早在2018年4月，禾苗团队向母婴零售店推出“PLUS会员”，提供11项全方位会员服务，权益包括优惠券大礼包、购物狂欢日、VIP专属商品区、24小时专属在线客服、VIP贵宾服务专区等。禾苗PLUS会员不仅适用于线上母婴商城，也适用于线下母婴零售店。</p>

      <p>禾苗PLUS会员体系有效划分了普通用户和会员用户，为普通用户与会员用户提供不同的产品和服务。通过PLUS会员体系，母婴店可以更加快速地掌握用户资料，了解用户消费习惯，挖掘用户的意向需求，针对不同用户提供个性化的优质服务。</p>

      <p>聚焦于母婴零售的消费者，他们不同于文娱、社交产品的消费者，他们更为关注产品本身的质量，对价格也更为敏感。在购买会员前，他们会思考计算更多时间。因此打造母婴零售会员体系的定位是价格驱动，而并非个人主动意愿驱动。母婴零售店则需要加强产品增值和产品体验，提升用户的价格感知能力。</p>

      <p>在此基础上，禾苗PLUS会员体系通过“扩大价格优惠权益、扩大专属权益、扩大跨界会员权益”三方面来提升会员的产品体验，11项会员服务全方面提升会员消费体验。禾苗PLUS会员体系有效地加强用户忠诚度和消费粘性，加强用户产品体验，使得用户在不断体验产品过程中，逐渐提升用户口碑、体验驱动力。</p>

      <p>内容为王的时代，产品增值和体验感受决定了用户的价值感知，内容质量逐渐成为用户消费的绝对因素。禾苗PLUS会员体系，正是因此而生。线上线下一体化的会员体系，或许正是母婴店突破发展瓶颈的关键点。</p>
    </article>
    <article v-if="index === 3" class="article">
      <div class="text-center"><img src="https://imgcdn.hemiao100.com/weihaojiao/upload/rechargeAdvertise/image/42010016042552/2018-11/edd28efa-8455-4f73-a072-487cad07c8c1.jpg" alt="" class="img-fluid mb40"></div>

      <p>2018年10月9日，禾苗团队核心成员走访了九江爱婴宝母婴店。</p>

      <p>走访过程中，禾苗团队CEO万总，运营总监彭总，以及技术总监、产品经理等众多高层一行人，不仅参观了爱婴宝母婴用品的实体店，还深入了解爱婴宝母婴店工作人员在使用禾苗系统时出现的难点疑点，并及时给予解答和整改。</p>

      <p>自禾苗与爱婴宝达成战略合作后，双方表示合作已经取得良好的效果，得到了工作人员以及消费者的大力支持。</p>

      <p>纵观中国母婴市场，二胎开放利好、品牌消费升级，形势一片大好。但母婴市场鱼龙混杂，竞争激烈，传统母婴经营者按照传统的营销方式就比较艰难。“不懂得借用移动互联网、不懂得新零售概念、不懂得线上线下一体化”是目前大多数传统母婴经营者的痛点。运用新零售概念、互联网营销思维、线上打通线下方式才是传统母婴店的解决上策。</p>

      <p>在此背景下，爱婴宝一直保持高速发展，这得益于爱婴宝负责人对新零售和移动互联网的认识，也离不开禾苗等服务商一直以来的合作。禾苗团队在互联网开发方面的经验和爱婴宝多年来累积的渠道、客户资源相结合，使得双方实现共赢。</p>

      <p>爱婴宝使用的移动互联网系统，正是禾苗的核心产品——“智慧零售”会员精准营销系统。该系统运用互联网、大数据和人工智能等技术，构建商品、用户、消费等零售要素的数字化，销售、服务等运营的智能化。禾苗将其先进的信息技术、运营策略、管理方法与爱婴宝实际情况相结合，为爱婴宝提供全方位的运营方案，有效地帮助爱婴宝拓展市场、提高管理水平。</p>

      <p>此次走访，禾苗团队结合爱婴宝实际使用情况，对导购、店长等工作人员再一次进行操作培训，解答近段时间内爱婴宝工作人员在使用禾苗系统时出现的问题，使得爱婴宝团队能够更好地服务消费者，更稳健地开拓新市场！</p>
    </article>
    <article v-if="index === 4" class="article">
      <div class="text-center"><img src="https://imgcdn.hemiao100.com/weihaojiao/upload/rechargeAdvertise/image/42010016042552/2018-11/6e4ff499-99ad-4509-90ce-10e358f60b89.png" alt="" class="img-fluid mb40"></div>
      <p>2017年，禾苗向母婴零售店推出“会员数字化”，禾苗认为，未来母婴零售的竞争核心就是“会员数字化”，数据驱动的智慧零售，与传统零售有着本质的不同。</p>

      <p>因为人类大脑受到记忆力的限制，在这个大数据+互联网的时代，母婴店从传统的管理方式过渡到基于数据的管理方式将会成为必然的趋势。禾苗“会员数字化”能够帮助母婴店对会员和数据有更全面的认识。</p>

      <p>在过去一年时间里，母婴零售店遇到的挑战层出不穷，运营者们曾以为抢走客户的是各大电商，其实不然，移动互联网才是母婴运营者们需要面对的难题。</p>

      <p>数字化就是应对的最好方式。数字化讲究场景应用，结合数据分析，制定高效决策。数字化给大数据的实践落地提供了检验依据，大量、枯燥、繁琐的数据可以通过合理的数据可视化实践。在这个大数据+互联网的时代，大数据的绝大部分应用都将以数据可视化的形式呈现。</p>

      <p>禾苗“会员数字化”体现在会员生理轴分布、门店目标监控、实时数据大屏等方面。禾苗通过折线图、柱状图、饼图、雷达图等多种形式的图表体现。“会员数字化”有效整合了会员关键信息，帮助导购实现精准客户维护；三秒展示导购销售数据，轻松完成门店目标监控；更有实时数据大屏，促进管理者达成决策。</p>

      <p>禾苗“会员数字化”的发展，将改变传统母婴店的管理运营方式，让数据和资料的呈现更及时、直接、简单。让母婴零售店的管理更客观，销售更有针对性。</p>

      <p>简单点来说，数字化可以承载让母婴店工作人员更容易理解和操作的工作，辅助大数据+互联网的神秘，让用户体验更佳，让母婴零售店在母婴市场拥有更核心的竞争力！</p>
    </article>
    <article v-if="index === 5" class="article">
      <div class="text-center"><img src="https://imgcdn.hemiao100.com/weihaojiao/upload/rechargeAdvertise/image/42010016042552/2018-11/116cbc85-bc47-47d2-826c-b9b94d6df874.jpg" alt="" class="img-fluid mb40"></div>
      <p>自2015年起，禾苗就专注于为母婴店提供“大数据+新零售”会员精准营销系统。禾苗仅通过一年时间，就在行业内推出了首个母婴“智慧零售”的会员精准营销系统，“线上商城”就是该系统不可或缺的重要组成之一。</p>

      <p>马云就曾说过，阿里巴巴不是线上销售，是零售，是实体，无论是线上线下，已经没有那么清晰的界限了。放在日常中也不难理解，设想客户在线上购买商品时，遇见不认识的品牌，也会希望在线下实体店看看产品；在实体店经常购买的或是有品牌的商品，就会选择在网上购买。</p>

      <p>所以现在不太存在绝对的线下和绝对的线上，尤其是伴随消费者对母婴商品质量的严苛需求，线上线下一体化将在母婴行业得到更广泛、深化的推行。</p>

      <p>母婴消费者收入水平提高、国家二胎政策开放，未来母婴消费比重会不断加大，线上母婴消费市场发展潜力巨大。母婴消费者对商品的品质性、情感性有着丰富诉求，这也更考验线上平台的供应链能力、运营力和服务力。</p>

      <p>禾苗提供的“线上商城”，具有清晰的产品库、标签库、海报库，带给客户极好的购物体验。商城提供形式多样的优惠券和积分兑换功能，满足更多用户的购物需求，增加线上购物的丰富性和趣味性。禾苗的“线上商城”同时起着重要的引流线下的功能，线上多项功能同步线下，商城和门店一体化，真正做到线上商场+线下门店跨界零售。</p>

      <p>对于母婴店来说，单独做电商或是一昧地经营实体店，都不是长久之计。走出传统的营销模式，线上结合线下，线上对应互联网和大数据，线下对应实体店和服务，两者相辅相成，才能帮助母婴店走得更远。</p>
    </article>
    <article v-if="index === 6" class="article">
      <div class="text-center"><img src="https://imgcdn.hemiao100.com/weihaojiao/upload/rechargeAdvertise/image/42010016042552/2018-11/40f4eeba-f0d7-4fb6-ab44-ecfe6e4517d3.jpg" alt="" class="img-fluid mb40"></div>

      <p>近年来，答题测试类游戏频频刷屏，我们能想到的或是想不到的答题测试几乎都出现了：性格、隐藏人格、你的使用说明书、音乐类测试......隔三差五席卷了我们的朋友圈。</p>

      <p>知乎、百度、饿了么都用答题测试类游戏刷了屏，其中当属网易的答疑测试最深入人心。</p>

      <p>这类营销结果显著的答题类游戏还有很多，它们是品牌营销的实用利器。禾苗作为专业营销平台，也专为母婴店打造了一套答题游戏。</p>

      <p>禾苗系统通过用户参与答题，分享结果，最终用户获得优惠券。禾苗认为，答题游戏之于母婴店，在拉新留存等方面至少有这些好处：</p>

      <p class="no-margin">增加趣味性，提高用户粘性</p>
      <p class="no-margin">提高用户参与度，吸引更多人关注</p>
      <p class="no-margin">发放优惠券，促进用户消费</p>
      <p class="no-margin">增加门店和商城流量</p>
      <p class="no-margin">提高店铺销售量和收入</p>
      <p>……</p>

      <p class="no-margin">禾苗开发答题游戏之初，团队人员讨论了关于“如何通过答题促进品牌传播和门店销售额”。禾苗认为，需要一些重要且基本的元素，例如：</p>
      <p class="no-margin">轻松好玩，具有趣味性</p>
      <p class="no-margin">满足用户寻找谈资的需求</p>
      <p class="no-margin">参与门槛低，操作简单</p>
      <p class="no-margin">帮助用户形象树立、进行社会比较</p>
      <p>给用户带去实际的利益</p>

      <p>禾苗开发的答题游戏包含以上全部元素：用户参与门槛低，傻瓜式操作，通过顺序答题得出测试结果，一键分享测试结果还可获得优惠券，在消费时可直接使用。</p>

      <p>在游戏的设置上，后台的工作也是简单易操作。工作人员通过对“活动背景”、“测试结果”、“奖励配置”、“测试试题”的设置，十分钟即可完成一个游戏的后台设置。</p>

      <p>为什么禾苗能肯定用户会参与分享呢？测试结果文案是个非常关键的点。测试结果一般都是正面评价，结果富含趣味性且不失真实性。</p>

      <p>这里不得不提一个心理效应——巴纳姆效应（Barnum effect）。很多星座、性格、心理年龄等答题测试就是利用这个心理效应，用户都会习惯性对号入座。</p>

      <p>这也保证了此类活动的分享传播力度，尤其是用户在满足凸显自我的心态后，还可以获得真实有效的优惠券，消费时可直接使用，这一环节设置大大促进了用户参与、分享本次活动。</p>

      <p>禾苗的答题游戏无论在环节设置、文案创意、操作实用等方面都完全符合母婴店的需求，禾苗的技术团队和运营团队为母婴店提供可靠的技术实现和全面的运营支持。</p>

    </article>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      newsList: [
        {
          isEssence: true,
          title: '“HONG云”——中小型母婴连锁店的新希望',
          describe: '2018年9月，拥有丰富经验的互联网母婴服务商——禾苗，面向零售店发布了“HONG云”系列会员精准营销系统，针对性地解决中小型零售店客户开卡耗费时间长、开卡场景局限、会员维护不到位、导购管理混乱等问题。'
        },
        {
          isEssence: false,
          title: '“禾苗AI人脸识别技术，助力母婴新零售高速增长！',
          describe: '2018年9月，禾苗（武汉）信息技术有限公司打造了国内母婴行业首家AI人脸识别技术，利用AI人脸识别+大数据，助力母婴新零售的高效增长。'
        },
        {
          isEssence: false,
          title: '禾苗“PLUS会员”，突破母婴店发展瓶颈',
          describe: '2018年5月，京东PLUS与爱奇艺VIP打通一周，新增超百万会员，累计plus会员数同比2016年增速高达700%。'
        },
        {
          isEssence: false,
          title: '禾苗走访九江爱婴宝，坚持提供全站式服务！',
          describe: '2018年10月9日，禾苗团队核心成员走访了九江爱婴宝母婴店。'
        },
        {
          isEssence: false,
          title: '“会员数字化”——未来母婴零售的竞争核心',
          describe: '2017年，禾苗向母婴零售店推出“会员数字化”，禾苗认为，未来母婴零售的竞争核心就是“会员数字化”，数据驱动的智慧零售，与传统零售有着本质的不同。'
        },
        {
          isEssence: false,
          title: '母婴实体与商城，禾苗助力实现线上线下跨界零售',
          describe: '自2015年起，禾苗就专注于为母婴店提供“大数据+新零售”会员精准营销系统。禾苗仅通过一年时间，就在行业内推出了首个母婴“智慧零售”的会员精准营销系统，“线上商城”就是该系统不可或缺的重要组成之一。'
        },
        {
          isEssence: false,
          title: '玩转“答题型”裂变营销——禾苗推出实用利器！',
          describe: '自2015年起，禾苗就专注于为母婴店提供“大数据+近年来，答题测试类游戏频频刷屏，我们能想到的或是想不到的答题测试几乎都出现了：性格、隐藏人格、你的使用说明书、音乐类测试......隔三差五席卷了我们的朋友圈。'
        }
      ]
    }
  },
  computed: mapGetters(['index']),
  beforeDestroy () {
    this.$store.dispatch('queryNewsDetails', { isEssence: false, index: '' })
  },
  methods: {
    back () {
      this.$store.dispatch('queryNewsDetails', { isEssence: false, index: '' })
    }
  }
}
</script>

<style scoped lang="less">
.new-details-container {
  .news-container-caption {
    color: #666;
    padding-bottom: 20px;
    border-bottom: 1px #666 dashed;
    margin-bottom: 40px;
    a {
      font-size: 1.25rem;
    }
  }
  .article {
    p {
      text-indent: 2em;
      color: #666;
      margin-bottom: 20px;
    }
    .no-indent {
      text-indent: 0;
    }
    .no-margin {
      margin: 0;
    }
  }
}

.mb40 {
  margin-bottom: 40px;
}

@media (max-width: 767px) {
  .new-details-container {
    .news-container-caption {
      margin-bottom: 20px;
      h5 {
        margin-bottom: 0;
      }
    }
    .mb40 {
      margin-bottom: 20px;
    }
  }
}
</style>

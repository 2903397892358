<template>
    <div class="jobs-container">
        <b-container>
            <h5 class="jobs-container-title">
                招聘岗位
            </h5>
            <b-row class="position">
                <b-col class="position-item" cols="12" md="6">
                    <h5 class="job-title">数据运营专员</h5>
                    <div class="need">
                        <p>岗位要求：</p>
                        <p>1、计算机、信息技术、统计等相关专业优先；</p>
                        <p>2、熟悉数据库，对数据敏感，具有数据分析的能力和技巧；</p>
                        <p>3、具有sql、spss等常用数据处理工具的优先考虑；</p>
                        <p>4、对工作认真负责，具有良好的沟通协调能力，具有团队精神。</p>
                    </div>
                </b-col>
                <b-col class="position-item" cols="12" md="6">
                    <h5 class="job-title">运营助理</h5>
                    <div class="need">
                        <p>岗位要求：</p>
                        <p>1、市场营销、电子商务相关专业优先；</p>
                        <p>2、有淘宝、微商、微信公众号操作经验；</p>
                        <p>3、熟练掌握常用办公软件及基本的网络知识；</p>
                        <p>4、对工作认真负责，具有良好的沟通协调能力，具有团队精神。</p>
                    </div>
                </b-col>
                <b-col class="position-item" cols="12" md="6">
                    <h5 class="job-title">运营客服</h5>
                    <div class="need">
                        <p>岗位要求：</p>
                        <p>1、广告、新闻类、中文类相关专业优先；</p>
                        <p>2、有一定的文字功底，思维敏捷，语言表达能力强，擅长软文写作；</p>
                        <p>3、良好的执行力，具有较强的语言组织能力及沟通协调能力；</p>
                        <p>4、积极乐观，具有较好的客户服务意识；</p>
                        <p>5、对工作认真负责，具有良好的沟通协调能力，具有团队精神。</p>
                    </div>
                </b-col>
                <b-col class="position-item" cols="12" md="6">
                    <h5 class="job-title">销售代表</h5>
                    <div class="need">
                        <p>岗位要求：</p>
                        <p>1、具有从事销售工作的强烈意愿；</p>
                        <p>2、语言表达能力强，反应机敏，思路清晰。</p>
                        <p>3、工作认真负责，具有良好的沟通协调能力，具有团队精神，能承受较大的工作压力；</p>
                        <p>4、能接受出差。</p>
                    </div>
                </b-col>
                <b-col class="position-item" cols="12" md="6">
                    <h5 class="job-title">JAVA工程师</h5>
                    <div class="need">
                        <p>岗位要求：</p>
                        <p>1、计算机相关专业；</p>
                        <p>2、具备扎实的java基础，熟悉java核心api，熟悉j2ee应用服务器工作原理，熟悉Spring/Hibernate/webwork(struts)技术；</p>
                        <p>3、熟练掌握sql语言与mysql；</p>
                        <p>4、熟练使用html,JavaScript, jsp,ajax, jquery等网页技术，熟练使用div+css布局；</p>
                        <p>5、对工作认真负责，具有良好的沟通协调能力，具有团队精神。</p>
                    </div>
                </b-col>
            </b-row>
            <b-row class="flex-center hm-title">
                <b-col cols="3">
                    <hr />
                </b-col>
                <b-col cols="6" class="text-center">
                    <h3 class="hm-title">公司特色</h3>
                </b-col>
                <b-col cols="3">
                    <hr />
                </b-col>
            </b-row>
            <div class="work">
                <!-- <b-row>
                    <b-col cols="12" sm="8">
                         <p>1、工作时间：每天7.5小时工作制，享受国家法定假日休假，春节假期长达10天；</p>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12" sm="8">
                        <p>2、公司福利：具有竞争力的薪酬、年度薪酬调整、年度旅游、社会保险、年终奖、节日礼品等，领导好、氛围好、员工聚餐与活动、晋升机会多、学习机会多。</p>
                    </b-col>
                </b-row> -->
                <p>1、工作时间：每天7.5小时工作制，享受国家法定假日休假，春节假期长达10天；</p>
                <p>2、公司福利：具有竞争力的薪酬、年度薪酬调整、年度旅游、社会保险、年终奖、节日礼品等，领导好、氛围好、员工聚餐与活动、晋升机会多、学习机会多。</p>
            </div>
            <div class="contact">
                <p><a href="tel:15549487858 ">联系方式：15549487858 鲁先生 </a></p>
                <p><a href="mailto:1350711508@qq.com">Email ：1350711508@qq.com（简历投至此邮箱）</a></p>
                <p>
                    <router-link to="/">网 址：http://www.hemiao100.com/</router-link>
                </p>
                <p>地 址：武汉市江汉区武汉商务区泛海国际4栋2203</p>
            </div>
        </b-container>
    </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  computed: {

  },
  watch: {

  },
  methods: {

  }
}
</script>

<style scoped lang="less">
.jobs-container {
  .hm-title {
    margin-bottom: 40px;
  }
  p,
  a {
    color: #666;
    font-size: 16px;
    line-height: 20px;
  }
  .work,
  .contact {
    margin-bottom: 40px;
  }
  .position-item {
    margin-bottom: 40px;
  }
  .job-title {
    margin-bottom: 20px;
  }
  .jobs-container-title {
    color: #666;
    padding-bottom: 20px;
    border-bottom: 1px #666 dashed;
    margin-bottom: 40px;
  }
}

@media (max-width: 767px) {
    .jobs-container {
        .jobs-container-title {
            padding-bottom: 10px;
            margin-bottom: 20px;
        }
    }
    .work, .contact {
        p, a {
            font-size: 14px;
        }
    }
    .position-item {
        .job-title {
            font-size: 16px;
        }
        .need {
            p {
                font-size: 14px
            }
        }
    }
}
</style>

<template>
  <div class="stripe about-container">
    <div class="main-img-wrapper" v-if="activeTab === 1 && !isNewsDetials">
      <img src="https://imgcdn.hemiao100.com/hemiaoBase/website/news_banner.png" alt="主图" class="img-fluid">
    </div>
    <b-container>
      <div class="about-header">
        <div class="tabs">
          <button class="tab" @click="switchTab(i)" v-for="(v, i) in tabList" :key="i" :class="activeTab === i && 'active'">{{v.name}}</button>
        </div>
      </div>
      <div class="hm-card">
        <intro v-if="activeTab === 0" />
        <!-- <news v-if="activeTab === 1 && !isNewsDetials" />
        <news-details v-if="activeTab === 1 && isNewsDetials" /> -->
        <hx-news v-if="activeTab === 1 && !isNewsDetials" />
        <hx-news-details v-if="activeTab === 1 && isNewsDetials" />
        <jobs v-if="activeTab === 2" />
      </div>
    </b-container>
  </div>
</template>

<script>
import intro from '../components/Intro'
import jobs from '../components/Jobs'
// import news from '../components/News'
// import newsDetails from '../components/NewsDetails'
import hxNews from '../components/HxNews'
import hxNewsDetails from '../components/HxNewsDetails'
import { mapGetters } from 'vuex'
export default {
  // components: { intro, news, jobs, newsDetails },
  components: { intro, hxNews, jobs, hxNewsDetails },
  data () {
    return {
      activeTab: 2,
      tabList: [
        {
          name: '公司简介'
        },
        {
          name: '新闻中心'
        },
        {
          name: '工作机会'
        }
      ]
    }
  },
  mounted () {
    const { type } = this.$route.params
    this.typeToActiveTab(type)
  },
  computed: mapGetters(['type', 'isNewsDetials']),
  watch: {
    type (val) {
      this.typeToActiveTab(val)
    }
  },
  methods: {
    switchTab (i) {
      this.activeTab = i
      this.$router.push({ name: 'about', params: { type: this.activeTabToType() } })
    },
    activeTabToType () {
      let type = ''
      switch (this.activeTab) {
        case 0:
          type = 'intro'
          break
        case 1:
          // type = 'news'
          type = 'hxnews'
          break
        case 2:
          type = 'jobs'
          break
        default:
          break
      }
      return type
    },
    typeToActiveTab (type) {
      switch (type) {
        case 'intro':
          this.activeTab = 0
          break
        // case 'news':
        //   this.activeTab = 1
        //   break
        case 'hxnews':
          this.activeTab = 1
          break
        case 'jobs':
          this.activeTab = 2
          break
        default:
          break
      }
    }
  }
}
</script>

<style scoped lang="less">
@distance: 20px;
.about-header {
  margin-top: 53px;
  padding: @distance 0;
}

.tabs {
  text-align: center;
  .tab {
    position: relative;
    background: transparent;
    color: #636363;
    border-color: transparent;
    font-size: 16px;
    cursor: pointer;
  }
  .tab:nth-child(2) {
    margin: 0 3rem;
  }
  .active {
    background: #636363;
    color: #fff;
    transition: all 0.2s linear;
  }
  .tab:hover,
  .tab:focus {
    background: #636363;
    color: #fff;
    outline: none;
  }

  .tab:first-child:after {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -20px;
    content: '';
    width: 1px;
    height: 14px;
    background: #636363;
  }
  .tab:last-child:after {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -20px;
    content: '';
    width: 1px;
    height: 14px;
    background: #636363;
  }
}
.about-container {
  padding-bottom: 40px;
}

@media (max-width: 767px) {
  .tabs {
    .tab:nth-child(2) {
      margin: 0;
    }
    .tab:first-child:after {
      right: 0;
    }
    .tab:last-child:after {
      left: 0;
    }
  }
}
</style>

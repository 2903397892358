<template>
  <b-container class="news-container">
    <h5 class="news-container-caption">新闻中心</h5>
    <b-container>
      <div class="flex">
      <ul class="news-list">
        <li class="news-list-item" v-for="(v, i) in newsList" :key="i">
          <b-row>
            <b-col md="3" class="text-left" @click="view(i)">
              <img class="img-fluid news-img" :src="v.img" alt="人工发放成本高">
            </b-col>
            <b-col cols="12" md="9" :class="v.isEssence ? 'is-essence' : ''">
              <a @click="view(i)" href="javascript:void(0)">
                <div class="article-title"><h5>{{v.title}}</h5></div>
                <div class="describe">
                  {{v.describe}}
                </div>
                <div class="createTime">
                  {{v.createTime}}
                </div>
              </a>
            </b-col>
            <!-- <b-col md="2" class="text-right view-more">
              <b-button variant="outline-secondary" @click="view(i)">查看更多</b-button>
            </b-col> -->
          </b-row>
        </li>
      </ul>
      <div class="recommend pc">
        <div class="title">热门推荐</div>
        <ul>
          <li class="recommend-list-item" v-for="(v, i) in newsList" :key="i">
            <a @click="view(i)" href="javascript:void(0)" class="item">
              <div class="date">
                {{v.date}}
              </div>
              <div class="article-title">{{v.title}}</div>
            </a>
          </li>
        </ul>
      </div>
      </div>
    </b-container>
  </b-container>
</template>

<script>
export default {
  data () {
    return {
      newsList: [
        {
          isEssence: true,
          title: '“HONG云”——中小型母婴连锁店的新希望',
          describe: '2018年9月，拥有丰富经验的互联网母婴服务商——禾苗，面向零售店发布了“HONG云”系列会员精准营销系统，针对性地解决中小型零售店客户开卡耗费时间长、开卡场景局限、会员维护不到位、导购管理混乱等问题。',
          img: 'https://imgcdn.hemiao100.com/hemiaoBase/website/news_01.png',
          createTime: '2022-04-25 10:00:00'
        },
        {
          isEssence: false,
          title: '“禾苗AI人脸识别技术，助力母婴新零售高速增长！',
          describe: '2018年9月，禾苗（武汉）信息技术有限公司打造了国内母婴行业首家AI人脸识别技术，利用AI人脸识别+大数据，助力母婴新零售的高效增长。',
          img: 'https://imgcdn.hemiao100.com/hemiaoBase/website/news_02.png',
          createTime: '2022-04-25 10:00:00'
        },
        {
          isEssence: false,
          title: '禾苗“PLUS会员”，突破母婴店发展瓶颈',
          describe: '2018年5月，京东PLUS与爱奇艺VIP打通一周，新增超百万会员，累计plus会员数同比2016年增速高达700%。',
          img: 'https://imgcdn.hemiao100.com/hemiaoBase/website/news_03.png',
          createTime: '2022-04-25 10:00:00'
        },
        {
          isEssence: false,
          title: '禾苗走访九江爱婴宝，坚持提供全站式服务！',
          describe: '2018年10月9日，禾苗团队核心成员走访了九江爱婴宝母婴店。',
          img: 'https://imgcdn.hemiao100.com/hemiaoBase/website/news_04.png',
          createTime: '2022-04-25 10:00:00'
        },
        {
          isEssence: false,
          title: '“会员数字化”——未来母婴零售的竞争核心',
          describe: '2017年，禾苗向母婴零售店推出“会员数字化”，禾苗认为，未来母婴零售的竞争核心就是“会员数字化”，数据驱动的智慧零售，与传统零售有着本质的不同。',
          img: 'https://imgcdn.hemiao100.com/hemiaoBase/website/news_01.png',
          createTime: '2022-04-25 10:00:00'
        },
        {
          isEssence: false,
          title: '母婴实体与商城，禾苗助力实现线上线下跨界零售',
          describe: '自2015年起，禾苗就专注于为母婴店提供“大数据+新零售”会员精准营销系统。禾苗仅通过一年时间，就在行业内推出了首个母婴“智慧零售”的会员精准营销系统，“线上商城”就是该系统不可或缺的重要组成之一。',
          img: 'https://imgcdn.hemiao100.com/hemiaoBase/website/news_02.png',
          createTime: '2022-04-25 10:00:00'
        },
        {
          isEssence: false,
          title: '玩转“答题型”裂变营销——禾苗推出实用利器！',
          describe: '自2015年起，禾苗就专注于为母婴店提供“大数据+近年来，答题测试类游戏频频刷屏，我们能想到的或是想不到的答题测试几乎都出现了：性格、隐藏人格、你的使用说明书、音乐类测试......隔三差五席卷了我们的朋友圈。',
          img: 'https://imgcdn.hemiao100.com/hemiaoBase/website/news_03.png',
          createTime: '2022-04-25 10:00:00'
        }
      ]
    }
  },
  methods: {
    view (index) {
      this.$store.dispatch('queryNewsDetails', { isEssence: true, index })
    }
  }
}
</script>

<style scoped lang="less">

.news-container-caption {
  color: #666;
  padding-bottom: 20px;
  border-bottom: 1px #666 dashed;
  margin-bottom: 40px;
}
.news-list {
  padding: 0 2rem;
  width: 70%;
}
.news-list-item {
  color: #666;
  border-bottom: 1px #ccc dashed;
  padding: 30px 0;
  .article-title {
    margin-bottom: 20px;
    color: #333;
  }
  .describe {
    font-size: 16px;
    color: #666;
  }
  .createTime{
    font-size: 14px;
    color: #999999;
    margin-top: 10px;
  }
}
.news-list-item:first-child {
  padding-top: 0;
}

.news-list-item:last-child {
  border-bottom: none;
}

.is-essence {
  position: relative;
}

.is-essence::before {
  position: absolute;
  left: -12px;
  top: 0;
  background: red;
  color: #fff;
  content: '\7CBE';
  padding: 0 5px;
  border-radius: 3px;
}

.is-essence::after {
  position: absolute;
  left: -12px;
  top: 6px;
  content: '';
  border: 3px solid transparent;
  border-left-color: red;
}

.view-more {
  line-height: 60px;
}

a:visited {
  color: #2d64ff;
}

.recommend{
  margin: 0;
  font-size: 16px;
  width: 30%;
  padding-left: 2rem;
  .title{
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: bold;
    border-left: solid 4px #2D64FF;
    height: 20px;
    line-height: 20px;
    padding-left: 8px;
  }
  .recommend-list-item{
    margin-bottom: 10px;
    .item{
      height: 48px;
      line-height: 24px;
      overflow: hidden;
      color: #333333;
    }
    .date{
      padding-right: 20px;
    }
  }
}
@media (max-width: 767px) {
  .view-more {
    display: none;
  }
  .news-container-caption {
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
  .news-list {
    padding: 0;
    .news-list-item {
      padding-bottom: 20px;
      a {
        h5 {
          font-size: 16px;
        }
       .describe{
          font-size: 14px;
        }
        .createTime{
          font-size: 12px;
        }
      }
    }
    .news-list-item.is-essence:before {
      font-size: 12px;
      left: -24px;
    }
    .news-list-item.is-essence:after {
      left: -2px;
    }
  }
  .news-img{
    width: 100%;
    max-width: auto;
    margin-bottom: 10px;
  }
  .news-list {
    width: 100%;
  }
  .recommend{
    display: none;
  }
}
</style>
<template>
  <div>
    <b-container class="intro-container">
        <section>
          <article class="article">
            <b-row>
              <b-col cols="12" lg="6">
                <p>禾苗（武汉）信息技术有限公司，成立于2015年，专注于为母婴店提供“大数据+新零售”会员精准营销系统。团队有百度、腾讯、阿里巴巴、搜狗、优酷等优势资源支持，拥有百度联盟服务网络。</p>
                <p>
                  禾苗仅通过一年时间，就在行业内推出了首个专注于母婴行业“智慧零售”的会员精准营销系统，运用互联网、大数据和人工智能等技术，构建商品、用户、消费等零售要素的数字化，销售、服务等零售运营的智能化，成为国内领先的母婴行业互联网+服务商。
                </p>
                <p>
                  公司自成立以来，始终秉承着“让数据将经营变得更高效”的理念，将先进的信息技术、运营策略、管理方法与母婴店实际情况相结合，为母婴店提供会员精准营销系统和全方位的运营服务，帮助母婴店拓展市场、提高管理水平，使母婴店在激烈的市场竞争中始终保持竞争力，实现快速、稳定地发展。
                </p>
                <p>
                  截至2018年9月，禾苗已覆盖全国1000家母婴门店，超10000名母婴导购员在熟练使用禾苗导购系统，2000万妈妈正在使用禾苗母婴系统。
                </p>
              </b-col>
              <b-col cols="12" lg="6" class="img-wrapper">
                <img src="https://imgcdn.hemiao100.com/weihaojiao/upload/rechargeAdvertise/image/42010016042552/2018-11/e38c6427-b698-49ab-bcd1-717c525ed930.png" alt="公司标志" class="img-fluid">
              </b-col>
            </b-row>
          </article>
        </section>
        <b-row class="flex-center hm-title">
          <b-col cols="3">
            <hr />
          </b-col>
          <b-col cols="6" class="text-center">
            <h3>我们的发展</h3>
          </b-col>
          <b-col cols="3">
            <hr />
          </b-col>
        </b-row>
        <!-- <div class="time-line flex flex-around column">
      <div class="point top">
        <span class="time"><strong>2014</strong>.12</span>
        <h5 class="product">线上商城</h5>
      </div>
      <div class="point bottom">
        <span class="time"><strong>2015</strong>.06</span>
        <h5 class="product">在线销售平台1.0</h5>
      </div>
      <div class="point top">
        <span class="time"><strong>2016</strong>.03</span>
        <h5 class="product">在线销售平台2.0</h5>
      </div>
      <div class="point bottom">
        <span class="time"><strong>2016</strong>.07</span>
        <h5 class="product">导购管理平台1.0</h5>
      </div>
      <div class="point top">
        <span class="time"><strong>2016</strong>.12</span>
        <h5 class="product">导购管理平台2.0</h5>
      </div>
      <div class="point bottom">
        <span class="time"><strong>2017</strong>.08</span>
        <h5 class="product">导购管理平台3.0</h5>
      </div>
      <div class="point top">
        <span class="time"><strong>2017</strong>.12</span>
        <h5 class="product">大数据可视化平台</h5>
      </div>
      <div class="point bottom">
        <span class="time"><strong>2018</strong></span>
        <h5 class="product">PLUS会员</h5>
        <h5 class="product">AI人脸识别</h5>
      </div>
    </div> -->
    </b-container>
    <b-row>
      <b-col>
        <img src="../assets/exp.gif" class="img-fluid" alt="">
      </b-col>
    </b-row>
    <!-- <b-row class="mobile">
      <b-col>
        <img src="../assets/experience-mobile.gif" class="img-fluid" alt="">
      </b-col>
    </b-row> -->
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  computed: {

  },
  watch: {

  },
  methods: {

  }
}
</script>

<style scoped lang="less">
.intro-container {
  // padding-bottom: 20px;
  .article {
    p {
      color: #666;
      text-indent: 2em;
      font-size: 16px;
      line-height: 30px;
    }
  }
  .hm-title {
    margin: 40px 0 40px;
  }
}

.time-line {
  position: relative;
  .point {
    position: relative;
    z-index: 1;
    .product {
      color: #666;
    }
    .time {
      color: #2d64ff;
      strong {
        font-size: 20px;
      }
    }
  }
  .top {
    transform: translateY(-30%);
  }
  .bottom {
    transform: translateY(80%);
  }
  .top:before,
  .top:after,
  .bottom:before,
  .bottom:after {
    position: absolute;
    content: '';
    border-radius: 50%;
  }
  .top:before {
    bottom: 5px;
    left: 50%;
    width: 20px;
    height: 20px;
    background: #2d64ff;
  }
  .top:after {
    bottom: 9px;
    left: calc(~'50% + 4px');
    width: 12px;
    height: 12px;
    background: #fff;
  }
  .bottom:before {
    top: -32px;
    left: 50%;
    width: 20px;
    height: 20px;
    background: #2d64ff;
  }
  .bottom:after {
    top: -28px;
    left: calc(~'50% + 4px');
    width: 12px;
    height: 12px;
    background: #fff;
  }
}

.time-line:after {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  content: '';
  width: 100%;
  background: #96b1ff;
  height: 3px;
  border-radius: 30px;
  z-index: 0;
}

.pc {
  display: block;
}

.mobile {
  display: none;
}

@media (max-width: 992px) {
  .img-wrapper {
    margin: 0 auto 40px;
    order: -1;
  }
  .mobile {
    display: block;
  }

  .pc {
    display: none;
  }
  .time-line.column {
    flex-direction: column;
    width: 60%;
    margin: 0 auto;
    min-width: 288px;
    .point {
      margin-bottom: 30px;
      .product {
        margin-bottom: 0;
        font-size: 12px;
      }
      .time {
        font-size: 12px;
        strong {
          font-size: 16px;
        }
      }
    }
    .top {
      transform: translateY(0);
      padding-left: 30px;
    }
    .bottom {
      transform: translate(60%, 0);
    }
    .top:before {
      left: calc(~'50% - 8px');
    }

    .top::after {
      left: calc(~'50% - 4px');
    }

    .bottom:before {
      top: 50%;
      left: calc(~'-10% - 8px');
      transform: translateY(-50%);
    }
    .bottom:after {
      top: 50%;
      left: calc(~'-10% - 4px');
      transform: translateY(-50%);
    }
  }

  .time-line:after {
    display: none;
  }

  .time-line:before {
    position: absolute;
    top: 0;
    left: 50%;
    content: '';
    height: 100%;
    width: 3px;
    background: #96b1ff;
    z-index: 0;
  }
}

@media (max-width: 767px) {
  .hm-title {
    h3 {
      font-size: 20px;
    }
  }
  .img-wrapper {
    margin-bottom: 20px;
  }
  .intro-container {
    .article {
      p {
        font-size: 14px;
      }
    }
  }
}
</style>
